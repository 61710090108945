// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-infdsfdex-js": () => import("./../../../src/pages/infdsfdex.js" /* webpackChunkName: "component---src-pages-infdsfdex-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-basicpage-js": () => import("./../../../src/templates/basicpage.js" /* webpackChunkName: "component---src-templates-basicpage-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-product-type-js": () => import("./../../../src/templates/ProductType.js" /* webpackChunkName: "component---src-templates-product-type-js" */),
  "component---src-templates-products-page-js": () => import("./../../../src/templates/ProductsPage.js" /* webpackChunkName: "component---src-templates-products-page-js" */),
  "component---src-templates-project-type-js": () => import("./../../../src/templates/ProjectType.js" /* webpackChunkName: "component---src-templates-project-type-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/Projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/Services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-single-project-js": () => import("./../../../src/templates/SingleProject.js" /* webpackChunkName: "component---src-templates-single-project-js" */)
}

